<template>
  <div class="mm-detail">
      <div v-if="!isMobile" class="match-detail">
          <div class="ma-top">
              <div class="topleft">
                  <el-card shadow="never" ref="maLeft"
                  class="detail-box left-box">
                        <template #header>
                            <span>{{$t('message.search.cargoname')}}</span>
                        </template>
                      <el-descriptions class="margin-top"  :column="1" >
                          <el-descriptions-item :label="$t('message.cargo.cargoName')">{{lang=='en'?cargoLeft.cargoEname:cargoLeft.cargoCname}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.cargo.quantity')">{{cargoLeft.quantity}}&nbsp;{{cargoLeft.quantityUnit}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.cargo.laycan')">{{cargoLeft.laycanStart}} ~ {{cargoLeft.laycanEnd}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.cargo.loadingport')">{{lang=='en'?cargoLeft.loadingPortEname:cargoLeft.loadingPortCname}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.cargo.dischargingport')">{{lang=='en'?cargoLeft.dischargePortEname:cargoLeft.dischargePortCname}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.cargo.shorthandname')">{{cargoLeft.shorthandname}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.cargo.commission')">{{cargoLeft.commission}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.cargo.loadrate')">{{cargoLeft.loadrate}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.cargo.dischargerate')">{{cargoLeft.dischargerate}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.cargo.memo')">{{cargoLeft.memo}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.cargo.sender_time')">{{cargoLeft.sendTime}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.cargo.sender')">{{cargoLeft.senderEmail}}<el-button v-if="!cargoLeft.myFlag" size="mini" style="margin-left:10%" @click="lookcargosender">{{$t('message.check')}}</el-button></el-descriptions-item>
                      </el-descriptions>
                      <collapse-transition name="draw" >
                          <div class="collapse-wrap" v-show="cargoisActive" >
                              <slot>
                                  <el-descriptions  :column="1">
                                      <el-descriptions-item :label="$t('message.cargo.maxWeight')">{{cargoLeft.maxWeight}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.cargo.maxsize')">{{cargoLeft.maxsize}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.cargo.maxLength')">{{cargoLeft.maxLength}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.cargo.maxWidth')">{{cargoLeft.maxWidth}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.cargo.maxHeight')">{{cargoLeft.maxHeight}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.cargo.cargotype')">{{lang=='en'?cargoLeft.cargoTypeEname:cargoLeft.cargoTypeCname}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.cargo.loadrequest')">{{cargoLeft.loadrequest}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.cargo.package')">{{cargoLeft.package}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.cargo.sf')">{{cargoLeft.sf}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.cargo.crane')">{{cargoLeft.crane}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.cargo.term')">{{cargoLeft.term}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.cargo.frt')">{{cargoLeft.frt}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.cargo.currencyUnit')">{{cargoLeft.currencyUnit}}</el-descriptions-item>
                                  </el-descriptions>
                              </slot>
                          </div>
                      </collapse-transition>
                      <div style="text-align: center;position: sticky;bottom:0">
                          <el-button type="text" @click="cargoupanddown">{{$t('message.detailinfo')}}<i id="cargoupdown" class="el-icon-arrow-down"></i>
                          </el-button>
                      </div>
                  </el-card>
                  <!-- <div class="vs-box">
                      <img src="../../assets/img/vs.png" />
                  </div> -->
                  <el-card shadow="never" ref="maRight" class="detail-box right-box">
                        <template #header>
                            <span>{{$t('message.addtonnage.name')}}</span>
                        </template>
                      <el-descriptions class="margin-top"  :column="1" >
                          <el-descriptions-item :label="$t('message.tonnage.vesselName')">{{lang=='en'?tonnageRight.shipVesselEname:tonnageRight.shipVesselCname}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.tonnage.dwt')">{{tonnageRight.dwt}} {{$t('message.T')}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.tonnage.built')">{{tonnageRight.built}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.tonnage.openDate')">{{tonnageRight.openStartDate}} ~ {{tonnageRight.openEndDate}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.tonnage.openArea')">{{lang=='en'?tonnageRight.openSeaareaEname:tonnageRight.openSeaareaCname}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.tonnage.openPort')">{{lang=='en'?tonnageRight.openPortEname:tonnageRight.openPortCname}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.addtonnage.intent_area')">{{lang=='en'?tonnageRight.intentSeaareaEname:tonnageRight.intentSeaareaCname}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.addtonnage.intent_country')">{{lang=='en'?tonnageRight.intentCountryEname:tonnageRight.intentCountryCname}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.addtonnage.intent_port')">{{lang=='en'?tonnageRight.intentPortEname:tonnageRight.intentPortCname}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.addtonnage.memo')">{{tonnageRight.memo}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.addtonnage.sendtime')">{{tonnageRight.sendTime}}</el-descriptions-item>
                          <el-descriptions-item :label="$t('message.addtonnage.sender')">{{tonnageRight.senderEmail}}<el-button v-if="!tonnageRight.myFlag"  size="mini" style="margin-left:10%" @click="looktonnagesender">{{$t('message.check')}}</el-button></el-descriptions-item>
                      </el-descriptions>
                      <collapse-transition name="draw" >
                          <div class="collapse-wrap" v-show="isActive" >
                              <slot>
                                  <el-descriptions  :column="1">
                                      <el-descriptions-item :label="$t('message.addtonnage.sszz')">{{tonnageRight.pPT}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.addtonnage.shgz')">{{tonnageRight.rVTG}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.addtonnage.tyzq')">{{tonnageRight.fORTC}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.bale')">{{shipForm.bale}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.loa')">{{shipForm.loa}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.beam')">{{shipForm.beam }}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.depth')">{{shipForm.depth}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.callSign')">{{shipForm.callSign}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.deckNum')">{{shipForm.deckNum}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.draft')">{{shipForm.draft}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.gear')">{{shipForm.gear}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.hatch')">{{shipForm.hatch}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.hold')">{{shipForm.hold}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.imo')">{{shipForm.imo}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.ship_class')">{{shipForm.ship_class}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.nrt')">{{shipForm.nrt}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.pi')">{{shipForm.pi}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.type')">{{shipForm.type}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.tpc')">{{shipForm.tpc}}</el-descriptions-item>
                                      <el-descriptions-item :label="$t('message.ship.flag')">{{shipForm.flag}}</el-descriptions-item>
                                  </el-descriptions>
                              </slot>
                          </div>
                      </collapse-transition>
                      <div style="text-align: center;position: sticky;bottom:0">
                          <el-button type="text" @click="upanddown">{{$t('message.detailinfo')}} <i id="updown" class="el-icon-arrow-down"></i>
                          </el-button>
                      </div>
                  </el-card>
              </div>
              <h3>{{$t('message.otherinfo')}}</h3>
              <div class="block">
                  <el-carousel indicator-position="outside" height="170px">
                      <el-carousel-item v-for="(item,i) of match_list" :key="i" >
                          <vs-card :matchLeft="item.cargoCardModel" :matchRight="item.tonnageCardModel" :collect="TonnageCargo"></vs-card>
                      </el-carousel-item>
                  </el-carousel>
              </div>
          </div>
          <div class="resize" title="收缩侧边栏">
              ⋮
          </div>
          <div class="ma-haitu">
              <Mapbox   ref="mychild" :temp='1' str="tcmatch"></Mapbox>
          </div>
      </div>
      <div v-if="isMobile" class="match-detail-mobile">
            <div class="ma-top">
              <el-card shadow="never" class="detail-box">
                    <template #header>
                        <span>船舶动态</span>
                    </template>
                    <el-descriptions class="margin-top"  :column="1" >
                        <el-descriptions-item label="Vessal Name">Sea Champion</el-descriptions-item>
                        <el-descriptions-item label="Vessal Type">Bulk Carrier</el-descriptions-item>
                        <el-descriptions-item label="Open Date">2021/02/03--2021/02/07</el-descriptions-item>
                        <el-descriptions-item label="Open Area">eastren mediterrnanean(EMED)</el-descriptions-item>
                        <el-descriptions-item label="Open Port">Piraeus</el-descriptions-item>
                        <el-descriptions-item label="DWT/Draft">488.00 / 11.623</el-descriptions-item>
                        <el-descriptions-item label="Loa / Beam / Depth ">189.96 / 32.20 / 16.50</el-descriptions-item>
                        <el-descriptions-item label="P&I Club/ Class / Flag">the USclub / NKK / Greece</el-descriptions-item>
                        <el-descriptions-item label="Built / Grain / Bale ">2005 / 2125.36 / 2113.38</el-descriptions-item>
                        <el-descriptions-item label="Hatch / Hold / Deck_num">5 / 5 / SINGLE DECK</el-descriptions-item>
                        <el-descriptions-item label="Gear">4 x 30 mts cranes + 4 x 12 cm grabs</el-descriptions-item>
                        <el-descriptions-item label="Sender"><a>Kostas Tsampas</a></el-descriptions-item>
                    </el-descriptions>
              </el-card>
              <div class="vs-box">
                  <img src="../../assets/img/vs.png" />
              </div>
              <el-card shadow="never" class="detail-box">
                    <template #header>
                        <span v-if="this.tag === 'cargo'">货盘信息</span>
                        <span v-if="this.tag === 'tct'">租船信息</span>
                    </template>
                    <el-descriptions class="margin-top"  :column="1" >
                        <el-descriptions-item label="Cargo Name">PROJECT CARGO</el-descriptions-item>
                        <el-descriptions-item label="Cargo Type">None</el-descriptions-item>
                        <el-descriptions-item label="Laycan">2021/02/03--2021/02/28</el-descriptions-item>
                        <el-descriptions-item label="Loading Port">Gebze</el-descriptions-item>
                        <el-descriptions-item label="Discharging Port">Piraeus</el-descriptions-item>
                        <el-descriptions-item label="Quantity">447.66</el-descriptions-item>
                        <el-descriptions-item label="Package">None</el-descriptions-item>
                        <el-descriptions-item label="Term">None</el-descriptions-item>
                        <el-descriptions-item label="Frt / Commission(%)">None / 3.75</el-descriptions-item>
                        <el-descriptions-item label="S.F / L/D Rate">0.00 / 0.0</el-descriptions-item>
                        <el-descriptions-item label="Crane">None</el-descriptions-item>
                        <el-descriptions-item label="Sender"><a>Harish</a></el-descriptions-item>
                    </el-descriptions>
              </el-card>
          </div>
          <div class="ma-haitu"></div>
      </div>
  </div>
</template>

<script>
import { mapGetters  } from 'vuex'
import {searchTonnagebyId,matchCargo,searchTonnagepayed} from "../../api/tonnage";
import {searchCargobyId, searchCargopayed} from "../../api/cargo";
import Mapbox from '../../components/mapbox/Mapbox'
import { getshipinfo } from "../../api/metadata";
import VsCard from '../../components/vs-card/VsCard.vue'
import { ElMessageBox } from "element-plus";
import { getMypoints } from "../../api/integral";

export default {
  components:{Mapbox,VsCard},
  inject:['reload'],
 data(){
   return{
     cargoisActive:false,
     isActive:false,
     lang:'',
     match_list:[],
     tonnagepaiedForm: {
       consume: '',
       giftId: '',
       giftType: '',
       id: ''
     },
     cargopaiedForm: {
       consume: '',
       giftId: '',
       giftType: '',
       id: ''
     },
     pagination: {
       current: 0,
       pagesize: 4,
       total: 0
     },
       cargoLeft:{},
       tonnageRight:{},
        shipForm:{},
       cargo_id:0,//传进来的cargo信息的id
       tag:'',//传进来的另一个匹配信息标识：‘cargo’或‘tct’
       tag_id:0,//另一个匹配信息的id,
       scrollFlag:false
   }
 },
  watch:{
    '$route'(to, form) {
      this.reload()
    }
  },
 computed:{
     ...mapGetters(['isMobile'])
 },
 methods:{
  async initmatch(){
    await searchTonnagebyId(this.tag_id).then(response =>{
       if(response.error_code==200){
         this.tonnageRight = response.data
         getshipinfo(this.tonnageRight.shipId).then(res =>{
           this.shipForm = res.data
         })
       }
     })
    await searchCargobyId(this.cargo_id).then(response =>{
       if(response.error_code==200){
         this.cargoLeft = response.data
       }
     })
    this.$refs.mychild.tcmatch(this.tonnageRight.openSeaareaId,this.tonnageRight.openPortId,this.cargoLeft.loadingPortId,this.cargoLeft.dischargePortId,this.cargo_id)
  },

   async looktonnagesender() {
     this.tonnagepaiedForm.id = this.tag_id
     this.tonnagepaiedForm.consume = false
     this.tonnagepaiedForm.giftType = ''
     if (this.righttemp == 'true') {  //信息都为自己或已购买
       ElMessageBox.confirm(
           window.vm.$t('message.buycheck'),
        {
           confirmButtonText: window.vm.$t('message.OK'),
           cancelButtonText: window.vm.$t('message.cancel'),
           type: 'info',
         }
       ).then(() => {
         searchTonnagepayed(this.tonnagepaiedForm).then(response => {
           if (response.error_code == 200) {
             this.tonnageForm.senderEmail = response.data.senderEmail
           }
         })
       })
     }
     else if(this.righttemp == 'false' && this.tonnageRight.myFlag == 'true'){  //左侧未购买，右侧已购买，当前左侧信息可以免费匹配
       ElMessageBox.confirm(
          window.vm.$t('message.freechance'),
           window.vm.$t('message.Tips'),
        {
           confirmButtonText: window.vm.$t('message.OK'),
           cancelButtonText: window.vm.$t('message.cancel'),
           type: 'info',
         }
       ).then(() => {
         this.tonnagepaiedForm.consume = true  //触发免费匹配
         this.tonnagepaiedForm.id = this.tag_id
         this.tonnagepaiedForm.giftId = this.cargo_id
         this.tonnagepaiedForm.giftType = 2
         this.tonnagepaiedForm.matchType = 1
         searchCargopayed(this.tonnagepaiedForm).then(response => {
           if (response.error_code == 200) {
             this.tonnageRight.senderEmail = response.data.senderEmail
           }
         })
       })
     }
     else{
       await getMypoints().then(response => {
         this.currentPoint = response.data
       })
       if (this.currentPoint >= 1) {
         ElMessageBox.confirm(
             window.vm.$t('message.isbuy'),
             window.vm.$t('message.Tips'),
             {
               confirmButtonText: window.vm.$t('message.OK'),
               cancelButtonText: window.vm.$t('message.cancel'),
               type: 'info',
             }
         ).then(() => {
           this.tonnagepaiedForm.consume = true  //积分够，点击确认购买，将consume改为true
           searchTonnagepayed(this.tonnagepaiedForm).then(response => {
             if (response.error_code == 200) {
               this.tonnageRight.senderEmail = response.data.senderEmail
             }
           })
         })
       } else {           //积分不足，前去充值
         ElMessageBox.confirm(
             window.vm.$t('message.gotobuy'),
             window.vm.$t('message.Tips'),
             {
               confirmButtonText: window.vm.$t('message.OK'),
               cancelButtonText: window.vm.$t('message.cancel'),
               type: 'info',
             }
         ).then(() => {
           this.$router.push("/person/personalmessage/deposit")
         })
       }
     }
   },
   async lookcargosender() {
     this.cargopaiedForm.id = this.cargo_id
     this.cargopaiedForm.consume = false
     this.cargopaiedForm.giftType = ''
     if (this.lefttemp == 'true') {
       ElMessageBox.confirm(
           window.vm.$t('message.buycheck'),
           {
             confirmButtonText: window.vm.$t('message.OK'),
             cancelButtonText: window.vm.$t('message.cancel'),
             type: 'info',
           }
       ).then(() => {
         //  this.paiedForm.consume = true  积分够，点击确认购买，将consume改为true
         searchCargopayed(this.cargopaiedForm).then(response => {
           if (response.error_code == 200) {
             this.cargoLeft.senderEmail = response.data.senderEmail
           }
         })
       })
     }
     else if(this.lefttemp == 'false' && this.righttemp == 'true'){
       ElMessageBox.confirm(
          window.vm.$t('message.freechance'),
           window.vm.$t('message.Tips'),
        {
           confirmButtonText: window.vm.$t('message.OK'),
           cancelButtonText: window.vm.$t('message.cancel'),
           type: 'info',
         }
       ).then(() => {
         this.cargopaiedForm.id = this.cargo_id
         this.cargopaiedForm.consume = true  //触发免费匹配
         this.cargopaiedForm.giftId = this.tag_id
         this.cargopaiedForm.giftType = 1
         this.cargopaiedForm.matchType = 1
         searchTonnagepayed(this.cargopaiedForm).then(response => {
           if (response.error_code == 200) {
             this.cargoLeft.senderEmail = response.data.senderEmail
           }
         })
       })
     }
     else{
       await getMypoints().then(response => {
         this.currentPoint = response.data
       })
       if (this.currentPoint >= 1) {
         ElMessageBox.confirm(
             window.vm.$t('message.isbuy'),
             window.vm.$t('message.Tips'),
             {
               confirmButtonText: window.vm.$t('message.OK'),
               cancelButtonText: window.vm.$t('message.cancel'),
               type: 'info',
             }
         ).then(() => {
           this.paiedForm.consume = true  //积分够，点击确认购买，将consume改为true
           searchCargopayed(this.cargopaiedForm).then(response => {
             if (response.error_code == 200) {
               this.cargoLeft.senderEmail = response.data.senderEmail
             }
           })
         })
       } else {           //积分不足，前去充值
         ElMessageBox.confirm(
             window.vm.$t('message.gotobuy'),
             window.vm.$t('message.Tips'),
             {
               confirmButtonText: window.vm.$t('message.OK'),
               cancelButtonText: window.vm.$t('message.cancel'),
               type: 'info',
             }
         ).then(() => {
           this.$router.push("/person/personalmessage/deposit")
         })
       }
     }
   },
   upanddown(){
     var div = document.getElementById('updown');
     if( this.isActive == false){
       this.isActive = true
       div.className = 'el-icon-arrow-up'
     }else {
       this.isActive = false
       div.className = 'el-icon-arrow-down'
     }
   },
   cargoupanddown(){
     var div = document.getElementById('cargoupdown');
     if( this.cargoisActive == false){
       this.cargoisActive = true
       div.className = 'el-icon-arrow-up'
     }else {
       this.cargoisActive = false
       div.className = 'el-icon-arrow-down'
     }
   },
   dragControllerDiv: function () {
     var resize = document.getElementsByClassName('resize');
     var left = document.getElementsByClassName('ma-top');
     var mid = document.getElementsByClassName('ma-haitu');
     var box = document.getElementsByClassName('match-detail');
     var map = document.getElementsByClassName('mapboxgl-canvas');
     let _this = this
     for (let i = 0; i < resize.length; i++) {
       // 鼠标按下事件
       resize[i].onmousedown = function (e) {
         //颜色改变提醒
         resize[i].style.background = '#818181';
         var startX = e.clientX;
         resize[i].left = resize[i].offsetLeft- window.innerWidth * 0.12;
         // 鼠标拖动事件
         document.onmousemove = function (e) {
           var endX = e.clientX;
           var moveLen = resize[i].left + (endX - startX); // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
           var maxT = box[i].clientWidth - resize[i].offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度
           if (moveLen < 32) moveLen = 32; // 左边区域的最小宽度为32px
           if (moveLen > maxT - 300) moveLen = maxT - 300; //右边区域最小宽度为150px

           resize[i].style.left = moveLen; // 设置左侧区域的宽度

           for (let j = 0; j < left.length; j++) {
             left[j].style.width = moveLen + 'px';
             mid[j].style.width = (box[i].clientWidth - moveLen - 50) + 'px';
             _this.$refs.mychild.onResize()
           }
         };
         // 鼠标松开事件
         document.onmouseup = function (evt) {
           //颜色恢复
           resize[i].style.background = '#d6d6d6';
           document.onmousemove = null;
           document.onmouseup = null;
           resize[i].releaseCapture && resize[i].releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
         };
         resize[i].setCapture && resize[i].setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
         return false;
       };
     }
   },
   async TonnageCargo(){
     const params = {
       query:{},
       pagination: this.pagination
     }
     await matchCargo(params).then(response =>{
       if(response.error_code == 200){
         this.match_list=response.datas
        // console.log(this.match_list)
         this.pagination.total = response.pagination.total
       }
     })
   },
    changeScrollFlag(flag){
        this.scrollFlag = flag
    },
    //控制左右滚动条同步
    leftScroll() {
       let top = $(".left-box").scrollTop()
       if(!this.scrollFlag){ //当前操作的是左侧滚动条
           $(".right-box").scrollTop(top)
       }
    },
    rightScroll() {
       let top = $(".right-box").scrollTop()
       if(this.scrollFlag){ //当前操作的是右侧滚动条
           $(".left-box").scrollTop(top)
       }
    }
 },
  mounted(){
    this.dragControllerDiv()
  },
 created(){
     const { cargo_id , tag , tag_id  } = this.$route.params
     this.cargo_id = cargo_id
     this.tag = tag,
     this.tag_id = tag_id
   this.lefttemp=this.$route.query.lefttemp
   this.righttemp=this.$route.query.righttemp
   this.lang = localStorage.getItem('lang')
     this.initmatch()
   this.TonnageCargo()
 }
}
</script>

<style lang="scss">
.mm-detail{
    width:100%;
    height:100%;
    .match-detail{
        width:98%;
        //min-width: 500px;
        height:100%;
        .ma-top{
            float: left;
            width:45%;
            height:100%;
            /*display: flex;*/
            //overflow-y: auto;
            .el-card{
                height:100%;
                //overflow-y: scroll;
                overflow:auto !important;
            }
            .topleft {
                width: 100%;
                display: flex;
                height: 65%;
            }
            .block{
                width: 100%;
                /*height: 40%;*/
                bottom: 0px;
            }
            .el-carousel__item h3 {
                color: #475669;
                font-size: 14px;
                opacity: 0.75;
                line-height: 150px;
                margin: 0;
                text-align: center;
            }

            .el-carousel__item:nth-child(2n) {
                background-color: #99a9bf;
            }

            .el-carousel__item:nth-child(2n + 1) {
                background-color: #d3dce6;
            }
            .detail-box{
                flex:5;
                //border:2px solid #409eff;
                //border-radius: 10px;
                //background: pink;
                .el-card__header{
                    //height:5%;
                    padding:5px 20px;
                    //background: cornflowerblue;
                    border-bottom: 2px solid #409eff;
                    font-size: 16px;
                }
                .el-card__body{
                    padding: 10px 20px;
                    overflow-y: auto;
                }
                .el-descriptions :not(.is-bordered) td{
                    padding-bottom: 6px !important;
                }
                .el-descriptions__label{
                    font-weight: 600;
                }
            }
            .vs-box{
                flex:2;
                //background: chartreuse;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width:100%;
                    height:60%;
                }
            }
        }
        #map{
            height:100%;
            width:100%;

            /*margin:1.5% 2% 1.5% 1.5%;*/
            //background: pink;
        }
        .ma-haitu{
            float: left;
            width: 52%;
            height: 95%;
            /*background: pink;*/
            margin: 1%;

        }
        /*拖拽区div样式*/
        .resize {
            cursor: col-resize;
            float: left;
            position: relative;
            top: 45%;
            background-color: #d6d6d6;
            border-radius: 5px;
            margin-top: -10px;
            width: 10px;
            height: 50px;
            background-size: cover;
            background-position: center;
            /*z-index: 99999;*/
            font-size: 32px;
            color: white;
        }
        /*拖拽区鼠标悬停样式*/
        .resize:hover {
            color: #444444;
        }
    }
    .match-detail-mobile{
        width:98%;
        height:100%;
        .ma-top{
            width:100%;
            height:80%;
            display: flex;
            flex-direction: column;
            .el-card{
                overflow:auto !important;
            }
            .detail-box{
                width:100%;
                height:40%;
                //flex:5;
                //border:2px solid #409eff;
                //border-radius: 10px;
                //background: pink;
                .el-card__header{
                    //height:5%;
                    padding:5px 20px;
                    //background: cornflowerblue;
                    border-bottom: 2px solid #409eff;
                    font-size: 16px;
                }
                .el-card__body{
                    padding: 10px 20px;
                    overflow-y: auto;
                }
                .el-descriptions :not(.is-bordered) td{
                    padding-bottom: 6px !important;
                }
                .el-descriptions__label{
                    font-weight: 600;
                }
            }
            .vs-box{
                width:100%;
                height:20%;
                //flex:1;
                //background: chartreuse;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width:40%;
                    height:100%;
                }
            }
        }
        .ma-haitu{
            width:100%;
            height:52%;
            background: pink;
            margin-top: 1%;
        }
    }
}
</style>